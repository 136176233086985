@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GT-Flexa-Standard-Regular';
  src: local('GT-Flexa-Standard-Regular'),
    url(./assets/fonts/GT-Flexa-Standard-Regular.woff2) format('woff2'),
    url(./assets/fonts/GT-Flexa-Standard-Regular.woff) format('woff'),
    url(./assets/fonts/GT-Flexa-Standard-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'GT-Flexa-Standard-Regular';
  font-weight: 900;
  src: local('GT-Flexa-Standard-Regular'),
    url(./assets/fonts/GT-Flexa-Black-Trial.otf) format('opentype');
}

@font-face {
  font-family: 'GT-Flexa-Standard-Regular';
  font-weight: 700;
  src: local('GT-Flexa-Standard-Regular'),
    url(./assets/fonts/GT-Flexa-Bold-Trial.otf) format('opentype');
}

@font-face {
  font-family: 'Soehne-Buch';
  src: local('Soehne-Buch'),
    url(./assets/fonts/soehne-buch.woff2) format('woff2');
}

@font-face {
  font-family: 'OhnoSoftieDemo';
  font-weight: 300;
  src: local('OhnoSoftieDemo'),
    url('./assets/fonts/OhnoSoftieDemo-Light.otf') format('opentype');
}

@font-face {
  font-family: 'OhnoSoftieDemo';
  font-weight: 300;
  src: url('./assets/fonts/OhnoSoftieDemo-Light.otf') format('opentype');
}

@font-face {
  font-family: 'OhnoSoftieDemo';
  font-weight: 500;
  src: url('./assets/fonts/OhnoSoftieDemo-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'OhnoSoftieDemo';
  font-weight: 400;
  src: url('./assets/fonts/OhnoSoftieDemo-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'OhnoSoftieDemo';
  font-weight: 700;
  src: url('./assets/fonts/OhnoSoftieDemo-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'OhnoSoftieDemo';
  font-weight: 900;
  src: url('./assets/fonts/OhnoSoftieDemo-Black.otf') format('opentype');
}

@layer base {
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
