.dark .react-datepicker__month-container {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__header {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__header--has-time-select {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__month {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__week {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__time-container {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__header--time {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__time {
  background-color: rgb(30 41 59);
  color: white;
}

.dark .react-datepicker__day {
  color: white;
}

.dark .react-datepicker__day:hover {
  background-color: rgb(75 85 99);
}

.dark .react-datepicker__day--selected {
  background-color: rgb(75 85 99);
}

.dark .react-datepicker__time-list-item:hover {
  background-color: rgb(75 85 99) !important;
}

.dark .react-datepicker__time-list-item--selected {
  background-color: rgb(75 85 99) !important;
}

.dark .react-datepicker-time__header {
  color: white;
}

.dark .react-datepicker__current-month {
  color: white;
}

.dark .react-datepicker__day-name {
  color: white;
}

.dark .react-datepicker__day--disabled {
  color: rgb(129, 129, 129);
}

.dark .react-datepicker__day--disabled:hover {
  background-color: rgb(30 41 59);
}

.dark .react-datepicker__time-list-item--disabled:hover {
  background-color: rgb(30 41 59) !important;
  cursor: default;
}
