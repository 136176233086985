.gradientBg {
  background: linear-gradient(180deg, rgb(31, 31, 40) 0%, rgba(3, 4, 18, 1) 33%);
}

.headline {
  text-shadow: 2px 2px 12px #ef3e2d, 4px 4px 80px #ef3e2d;
}

.playground {
  box-shadow: 0px 0px 100px 5px #ef3e2d;
}

.linkUnderline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.25s ease-out;
}

.linkUnderline:hover {
  background-size: 100% 3px;
  background-position: 0 100%;
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}

.noScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.titleGradient {
  background-color: #f3ec78;
  background-image: linear-gradient(180deg, #ffffff, #b3b3b3);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.arrow {
  color: white;
}
.arrow::after {
  display: inline-block;
  padding-left: 8px;
  content: '\0279E';
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.arrow:hover {
  color: white;
}
.arrow:hover::after {
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  -ms-transform: translateX(4px);
  -o-transform: translateX(4px);
  transform: translateX(4px);
}

/* TODO - experiment to make landing page less boring  */
.dotsBackground {
  background-image: radial-gradient(rgba(255, 255, 255, 0.12) calc(10px * 0.1), rgba(0, 0, 0, 0.423) calc(10px * 0.1));
  background-size: 20px 20px;
  /*  background: radial-gradient(circle at 100% 105%, rgba(58, 58, 58, 1), rgba(33, 33, 33, 1), rgba(0, 0, 0, 1)); */
}
